
import { Options, Vue } from 'vue-class-component';
import api from '@/api';
import { Toast, Notify, Dialog } from 'vant';
@Options({
  components: {}
})
export default class Home extends Vue {
  loading = false
  block = {}
  pay: any = {}
  paySetTimeout: any;
  payClickSetTimeout: any;
  showIndex = 1
  // 清除倒计时
  PayClear () {
    if (this.paySetTimeout) {
      clearTimeout(this.paySetTimeout)
    }
    if (this.payClickSetTimeout) {
      clearTimeout(this.payClickSetTimeout)
    }
  }

  payTime = 0;
  payLink (item: any) {
    api
      .PayLink({
        order_code: this.order_code,
        pay_code: item.code
      })
      .then((data: any) => {
        if (data.status < 40) {
          this.payTime++
          // 控制30秒后结束循环
          if (this.payTime < 15) {
            this.payClickSetTimeout = setTimeout(() => {
              this.payLink(item)
            }, 2000)
          } else {
            Toast.fail('核保超时，请重试')
          }
          return
        }
        if (data.status > 40) {
          Toast('订单' + data.status_text + '，不能付款');
          return
        }
        this.PayOther(item)
      })
      .catch((error) => {
        Toast.fail(error.msg)
      })
  }

  copy (shareLink: any) {
    const input = document.createElement('textarea')// 直接构建input
    input.value = shareLink// 设置内容
    document.body.appendChild(input)// 添加临时实例
    input.select()// 选择实例内容
    document.execCommand('Copy')// 执行复制
    Toast.success('复制成功');
    document.body.removeChild(input) // 删除临时实例
  }

  getPayType () {
    this.loading = true
    api
      .PayType({
        order_code: this.order_code,
        outorg_code: this.outorg_code
      })
      .then((data: any) => {
        this.loading = false
        this.pay = data
        this.payLink(data.pay_type[0])
      })
      .catch((error: any) => {
        this.loading = false
        Toast.fail(error.msg)
      })
  }

  PayOther (item: any) {
    // 如果是线下
    // 防止重复进入线下页面，先清除定时器
    // clearTimeout(this.timer)
    api.PayParams({
      order_code: this.order_code,
      pay_code: item.code
    })
      .then((data) => {
        this.block = data
        this.EndTime = 200
        this.PayTimeout()
      })
      .catch((err) => {
        Dialog.alert({
          // eslint-disable-next-line comma-dangle
          message: err.data,
        }).then(() => {
          // on close
          location.href = this.pay.channel_url
        })
      })
  }

  // 扫码倒计时获取订单状态
  EndTime = 200
  PayTimeout () {
    this.PayEnd()
    this.paySetTimeout = setTimeout(() => {
      if (this.EndTime > 0) {
        this.EndTime--
        this.PayTimeout()
      }
    }, 3000)
  }

  // 获取支付状态
  PayEnd () {
    api
      .PayEnd({
        order_code: this.order_code
      })
      .then((data: any) => {
        if (data.status === 40) {
          this.goSuccess()
        }
      })
      .catch((error: any) => {
        Toast.fail(error.message)
      })
  }

  // 支付成功，同步回调
  // eslint-disable-next-line camelcase
  go_time = 3;
  goSuccess () {
    // 支付
    const timeJian = () => {
      this.go_time--
      if (this.go_time > 0) {
        setTimeout(() => {
          timeJian()
        }, 1000)
        return
      }
      location.href = this.pay.channel_url
      // location.href = this.getSuccessURL()
    }
    // 计时
    setTimeout(() => {
      timeJian()
    }, 1000)
  }

  // eslint-disable-next-line camelcase
  order_code: any = ''
  // eslint-disable-next-line camelcase
  outorg_code: any = ''
  created () {
    this.payTime = 0
    this.order_code = this.$route.query.order_code
    this.outorg_code = this.$route.query.outorg_code
    this.getPayType()
  }
}
